import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import WarriorStake from "./Pages/warriorStake";
import Home from "./Pages/home";
import PetStake from "./Pages/petStake";

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='/exopetstake' element={<PetStake />} />
					<Route path='/exowarrior' element={<WarriorStake />} />

				</Routes>
			</BrowserRouter>
		</div>




	)
}


export default App;
