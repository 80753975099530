import React, { Component } from 'react';
import pic1 from '../assets/1pic.png';
import pic2 from '../assets/2pic.png';
import pic3 from '../assets/3pic.png';
import pic4 from '../assets/4pic.png';
import coins from '../assets/coins.png';
import secretGif from '../assets/secret_images.gif';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import down from '../assets/down-arrow.png';
import pet1 from '../assets/warrior.png';
import '../App.css';
import logo from '../assets/logo.png';
import nft from '../assets/warrior2.png';
import bgNft from '../assets/bgNft.png';
import v2 from '../assets/v2.png';
import back from '../assets/back-button.png';

const website = () => {
	window.open("https://www.exo-warrior.com/");
}

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let ingvalue;
let myTokenIDs = [];
let x = '';
let myTokenURLs = '';
let myTokenIDsForm = [];
let z = 0;
let myStakeNFT = '';
let isApproved = false;
let getMyStakedTokens = [];
let getStakedDetailsWindow = 0;
let myStakedTokenCount;
let getRewards = [];
let q = -1;
let getRewardsRounded;
let elementArray = [];
let rewardsTime;
let rewardDetails;
let revealedStatus;
let getMyOwnNFTDetailsWindow = 0;
let rewardsAmount;
let myTokenAmount;
let pendingRewards = 0;
let allRewardsRealeasedByOwners = [];
let countOfOverallStakers;
let stakers;
let totalRewardReleased;
let milestone;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses =

	[

	];

let whitelistAddresses2 = [];

const ABIStaking = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "contractAddress",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_tokenID",
				"type": "uint256[]"
			}
		],
		"name": "awardsTransferIn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_tokenIDs",
				"type": "uint256[]"
			}
		],
		"name": "batchClaimRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_tokenIDs",
				"type": "uint256[]"
			}
		],
		"name": "batchStakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_tokenIDs",
				"type": "uint256[]"
			}
		],
		"name": "batchUnstakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimAwards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenID",
				"type": "uint256"
			}
		],
		"name": "claimRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "depositToContract",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"name": "onERC721Received",
		"outputs": [
			{
				"internalType": "bytes4",
				"name": "",
				"type": "bytes4"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_eml",
				"type": "string"
			}
		],
		"name": "register",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_bnbAwards",
				"type": "uint256"
			}
		],
		"name": "setbnbAwards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalNumber",
				"type": "uint256"
			}
		],
		"name": "setDecimalNumber",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_nftContract",
				"type": "address"
			}
		],
		"name": "setNFTContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardsCircle",
				"type": "uint256"
			}
		],
		"name": "setRewardsCircle",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_tokenId",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_rarityScore",
				"type": "uint256[]"
			}
		],
		"name": "rarityInjecter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_launchTime",
				"type": "uint256"
			}
		],
		"name": "setLaunchTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rarityCount",
				"type": "uint256"
			}
		],
		"name": "setRarityCount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rarityMode",
				"type": "uint256"
			}
		],
		"name": "setRarityMode",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardsAmount",
				"type": "uint256"
			}
		],
		"name": "setRewardsAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardsRate",
				"type": "uint256"
			}
		],
		"name": "setRewardsRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenID",
				"type": "uint256"
			}
		],
		"name": "stakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenID",
				"type": "uint256"
			}
		],
		"name": "unstakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "actionFigure",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "actionFigure1Count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "actionFigure2Count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "actionFigureList1",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "actionFigureList2",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "awardTransferOutCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "bnbAwards",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "countofMyStakedTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "countOfOverallStakers",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalNumber",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "deposit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "eml",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "launchTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "milestone",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "nft_awards_contracts",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "nft_awards_tokenIDs",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rarityCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rarityMode",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "rarityScores",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardsAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardsCircle",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardsRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "rewardsReceived",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "stakedTokenOwner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "stakedTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "stakers",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tokenStakedDuration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tokenStakedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "totalRewardReleased",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const addressStaking = "0x0b83688528D10E4a3aF3f85349158132b9665F27";

const ABIToken = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const addressToken = "0x276f3641B24E78d93E9c221E04bC51503130Cf5C";

const ABINFT = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_TX",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MINTING_PRICE",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "to",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "amount",
				"type": "uint256[]"
			}
		],
		"name": "addWL",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addr",
				"type": "address"
			}
		],
		"name": "checkHolderMintsLeft",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addr",
				"type": "address"
			}
		],
		"name": "checkWLMintsLeft",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holderClaim",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "holderMintingActive",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_to",
				"type": "address"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "mintReserve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mintingActive",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "reserveMint",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_value",
				"type": "bool"
			}
		],
		"name": "setHolderMintingActive",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "setMaxSupply",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "setMaxTx",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_value",
				"type": "bool"
			}
		],
		"name": "setMintingActive",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "setPrice",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "to",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "amount",
				"type": "uint256[]"
			}
		],
		"name": "setWL",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_value",
				"type": "bool"
			}
		],
		"name": "setWLMintingActive",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "wlClaim",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wlMintingActive",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const addressNFT = "0xB86b5aD06Ae4fe8EA38A683E1bF85d46a6c4737B";

let contractStaking;
let contractToken;
let contractNFT;

class WarriorStake extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_ingValue: '',
		_myTokenIDs: [],
		_obj: 'pic',
		_myTokenURLs: '',
		_num: '',
		_value: '',
		_myStakeNFT: '',
		_isApproved: false,
		_getMyStakedTokens: [],
		_getStakedDetailsWindow: 0,
		_myStakedTokenCount: '',
		_getRewards: [],
		_rewardDetails: [],
		_elementArray: [],
		_revealedStatus: false,
		_getMyOwnNFTDetailsWindow: 0,
		_rewardsAmount: '',
		_myTokenAmount: '',
		_pendingRewards: 0,
		_newPendingRewards: 0,
		_allRewardsRealeasedByOwners: [],
		_countOfOverallStakers: '',
		_stakers: '',
		_milestone: '',
		_totalRewardReleased: ''

	}

	async componentDidMount() {

		if (localStorage?.getItem('isWalletConnected') === 'true') {
			try {

				console.log("CONNECTED");



				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "bf933c3446b3464c988114813a1360ac" // required
						}
					}
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});

				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				//  if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("provider :" + web3Modal.cachedProvider);
					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					contractNFT = new web3.eth.Contract(ABINFT, addressNFT);
					console.log("contractNFT :" + contractNFT);

					contractToken = new web3.eth.Contract(ABIToken, addressToken);
					console.log("contractToken :" + contractToken);


					contractStaking = new web3.eth.Contract(ABIStaking, addressStaking);
					console.log("contractStaking :" + contractStaking);

					if (provider) {


						(async () => {


							if (provider != "walletconnect") {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								const chainId = 56;

								if (window.ethereum.networkVersion !== chainId) {
									try {
										await window.ethereum.request({
											method: 'wallet_switchEthereumChain',
											params: [{ chainId: web3.utils.toHex(chainId) }],
										});
									} catch (err) {
										// This error code indicates that the chain has not been added to MetaMask.
										if (err.code === 4902) {
											await window.ethereum.request({
												method: 'wallet_addEthereumChain',
												params: [
													{
														chainName: 'Smart Chain',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
														rpcUrls: ['https://bsc-dataseed.binance.org/'],

														/*chainName: 'Goerli  Network',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
														rpcUrls: ['https://goerli.infura.io/v3/'],*/
													},
												],
											});
										}
									}
								}





								try {

									totalSupplyNFT = await contractNFT.methods.totalSupply().call();
									this.setState({ totalSupply: totalSupplyNFT });
									console.log("Total Supply:" + totalSupplyNFT);

									isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
									this.setState({ _isApproved: isApproved });
									console.log(isApproved);

									publicSale = await contractNFT.methods.balanceOf(account).call();
									this.setState({ myNFTWallet: publicSale });

									revealedStatus = await contractNFT.methods.revealed().call();
									this.setState({ _revealedStatus: revealedStatus });

									myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
									console.log("myStakedTokenCount : " + myStakedTokenCount);

									rewardsAmount = await contractStaking.methods.rewardsAmount().call();
									this.setState({ _rewardsAmount: rewardsAmount });

									myTokenAmount = await contractToken.methods.balanceOf(account).call();
									this.setState({ _myTokenAmount: myTokenAmount });

									milestone = await contractStaking.methods.milestone(account).call();
									this.setState({ _milestone: milestone });

									totalRewardReleased = await contractStaking.methods.totalRewardReleased(account).call();
									this.setState({ _totalRewardReleased: totalRewardReleased });
									console.log("totalRewaedReleased:" + totalRewardReleased);

									let a;
									for (a = 0; a < myStakedTokenCount; a++) {
										getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
										this.setState({ _getMyStakedTokens: getMyStakedTokens });
										console.log("getMyStakedTokens : " + getMyStakedTokens);

										let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
										let timeDuration = Date.now() - (tokenTime * 1000);

										if (timeDuration >= 0) {

											getRewards[a] = (Date.now() - (tokenTime * 1000)) * 1 / 86400000;

										} else {
											getRewards[a] = 0;
										}

										elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(4)];
										this.setState({ _elementArray: elementArray });

										this.setState({ _getRewards: getRewards });
										console.log("tokenTime :" + tokenTime * 1000);
										console.log("tokenTimeDuration :" + timeDuration);
										console.log("getRewards : " + getRewards[a].toFixed(2));

										this.setState({ _getMyStakedTokens: getMyStakedTokens });
										console.log("getMyStakedTokens : " + getMyStakedTokens);
										console.log("elementArray[a] : " + elementArray[a]);
										console.log("elementArray[a][0] : " + elementArray[a][0]);

									}

									for (let q = 0; q < getRewards.length; q++) {
										pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
										this.setState({ _newPendingRewards: pendingRewards * 1 });
										console.log("_newPendingRewards :" + this.state._newPendingRewards);

									}


									for (let y = 0; y < this.state.myNFTWallet; y++) {
										myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
										this.setState({ _myTokenIDs: myTokenIDs })

									}

									console.log("myTokenIDs : " + myTokenIDs[0]);
									console.log("myTokenIDs : " + this.state._myTokenIDs);

									owner = await contractNFT.methods.owner().call();
									console.log("Owner" + owner);



								} catch (err) {
									console.log("err: " + err);

								}
							} else {



								if (WalletConnectProvider.networkVersion !== chainId) {
									try {
										await WalletConnectProvider.request({
											method: 'wallet_switchEthereumChain',
											params: [{ chainId: web3.utils.toHex(chainId) }],
										});
									} catch (err) {
										// This error code indicates that the chain has not been added to MetaMask.
										if (err.code === 4902) {
											await window.ethereum.request({
												method: 'wallet_addEthereumChain',
												params: [
													{
														chainName: 'Smart Chain',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
														rpcUrls: ['https://bsc-dataseed.binance.org/'],

														/*chainName: 'Goerli  Network',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
														rpcUrls: ['https://goerli.infura.io/v3/'],*/
													},
												],
											});
										}
									}
								}




								try {

									try {
										localStorage.setItem('isWalletConnected', true);
									} catch (ex) {
										console.log(ex)
									}

									totalSupplyNFT = await contractNFT.methods.totalSupply().call();
									this.setState({ totalSupply: totalSupplyNFT });

									console.log("Total Supply:" + totalSupplyNFT);

									isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
									this.setState({ _isApproved: isApproved });
									console.log(isApproved);

									publicSale = await contractNFT.methods.balanceOf(account).call();
									this.setState({ myNFTWallet: publicSale });

									revealedStatus = await contractNFT.methods.revealed().call();
									this.setState({ _revealedStatus: revealedStatus });

									myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
									console.log("myStakedTokenCount : " + myStakedTokenCount);

									rewardsAmount = await contractStaking.methods.rewardsAmount().call();
									this.setState({ _rewardsAmount: rewardsAmount });

									myTokenAmount = await contractToken.methods.balanceOf(account).call();
									this.setState({ _myTokenAmount: myTokenAmount });

									milestone = await contractStaking.methods.milestone(account).call();
									this.setState({ _milestone: milestone });

									totalRewardReleased = await contractStaking.methods.totalRewardReleased(account).call();
									this.setState({ _totalRewardReleased: totalRewardReleased });
									console.log("totalRewaedReleased:" + totalRewardReleased);

									let a;
									for (a = 0; a < myStakedTokenCount; a++) {
										getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
										this.setState({ _getMyStakedTokens: getMyStakedTokens });
										console.log("getMyStakedTokens : " + getMyStakedTokens);

										let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
										let timeDuration = Date.now() - (tokenTime * 1000);

										if (timeDuration >= 0) {

											getRewards[a] = (Date.now() - (tokenTime * 1000)) * 1 / 86400000;

										} else {
											getRewards[a] = 0;
										}

										elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(4)];
										this.setState({ _elementArray: elementArray });

										this.setState({ _getRewards: getRewards });
										console.log("tokenTime :" + tokenTime * 1000);
										console.log("tokenTimeDuration :" + timeDuration);
										console.log("getRewards : " + getRewards[a].toFixed(2));

										this.setState({ _getMyStakedTokens: getMyStakedTokens });
										console.log("getMyStakedTokens : " + getMyStakedTokens);
										console.log("elementArray[a] : " + elementArray[a]);
										console.log("elementArray[a][0] : " + elementArray[a][0]);

									}


									for (let q = 0; q < getRewards.length; q++) {
										pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
										this.setState({ _newPendingRewards: pendingRewards * 1 });
										console.log("_newPendingRewards :" + this.state._newPendingRewards);

									}

									for (let y = 0; y < this.state.myNFTWallet; y++) {
										myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
										this.setState({ _myTokenIDs: myTokenIDs });

									}

									console.log("myTokenIDs : " + myTokenIDs[0]);
									console.log("myTokenIDs : " + this.state._myTokenIDs);

									owner = await contractNFT.methods.owner().call();
									console.log("Owner" + owner);


								} catch (err) {
									console.log("err: " + err);

								}
							}
						})();

						//.....................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}
				}



				//await this.walletConnect();
			} catch (ex) {
				console.log(ex);
			}
		} else {

			console.log("NOT CONNECTED");

		}

	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contractNFT.methods.owner().call();


			//	if (account != owner) {


			try {


				console.log("minAmount:" + mintAmount);
				console.log("valueOfNFTs:" + valueOfNFTs);

				//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');

				this.setState({ statusError: false, statusLoading: true });
				await contractNFT.methods.mint(mintAmount * 1).send({ /*gasLimit: 285000, maxPriorityFeePerGas: "196000000000", maxFeePerGas: "202000000000", */from: account, value: this.state.totalValue * 0 });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.mintAmount);

			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}

		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		//  if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contractNFT = new web3.eth.Contract(ABINFT, addressNFT);
			console.log("contractNFT :" + contractNFT);

			contractToken = new web3.eth.Contract(ABIToken, addressToken);
			console.log("contractToken :" + contractToken);


			contractStaking = new web3.eth.Contract(ABIStaking, addressStaking);
			console.log("contractStaking :" + contractStaking);

			if (provider) {


				(async () => {


					if (provider != "walletconnect") {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						const chainId = 56;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Smart Chain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],

												/*chainName: 'Goerli  Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFT = await contractNFT.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
							this.setState({ _isApproved: isApproved });
							console.log(isApproved);

							publicSale = await contractNFT.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							revealedStatus = await contractNFT.methods.revealed().call();
							this.setState({ _revealedStatus: revealedStatus });

							myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
							console.log("myStakedTokenCount : " + myStakedTokenCount);

							rewardsAmount = await contractStaking.methods.rewardsAmount().call();
							this.setState({ _rewardsAmount: rewardsAmount });

							myTokenAmount = await contractToken.methods.balanceOf(account).call();
							this.setState({ _myTokenAmount: myTokenAmount });

							milestone = await contractStaking.methods.milestone(account).call();
							this.setState({ _milestone: milestone });

							totalRewardReleased = await contractStaking.methods.totalRewardReleased(account).call();
							this.setState({ _totalRewardReleased: totalRewardReleased });
							console.log("totalRewaedReleased:" + totalRewardReleased);

							let a;
							for (a = 0; a < myStakedTokenCount; a++) {
								getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
								this.setState({ _getMyStakedTokens: getMyStakedTokens });
								console.log("getMyStakedTokens : " + getMyStakedTokens);

								let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
								let timeDuration = Date.now() - (tokenTime * 1000);

								if (timeDuration >= 0) {

									getRewards[a] = (Date.now() - (tokenTime * 1000)) * 1 / 86400000;

								} else {
									getRewards[a] = 0;
								}

								elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(4)];
								this.setState({ _elementArray: elementArray });

								this.setState({ _getRewards: getRewards });
								console.log("tokenTime :" + tokenTime * 1000);
								console.log("tokenTimeDuration :" + timeDuration);
								console.log("getRewards : " + getRewards[a].toFixed(2));

								this.setState({ _getMyStakedTokens: getMyStakedTokens });
								console.log("getMyStakedTokens : " + getMyStakedTokens);
								console.log("elementArray[a] : " + elementArray[a]);
								console.log("elementArray[a][0] : " + elementArray[a][0]);

							}


							for (let q = 0; q < getRewards.length; q++) {
								pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
								this.setState({ _newPendingRewards: pendingRewards * 1 });
								console.log("_newPendingRewards :" + this.state._newPendingRewards);

							}


							for (let y = 0; y < this.state.myNFTWallet; y++) {
								myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs });

							}

							console.log("myTokenIDs : " + myTokenIDs[0]);
							console.log("myTokenIDs : " + this.state._myTokenIDs);

							owner = await contractNFT.methods.owner().call();
							console.log("Owner" + owner);


						} catch (err) {
							console.log("err: " + err);

						}
					} else {



						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Smart Chain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],

												/*chainName: 'Goerli  Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							totalSupplyNFT = await contractNFT.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);


							isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
							this.setState({ _isApproved: isApproved });
							console.log(isApproved);

							publicSale = await contractNFT.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							revealedStatus = await contractNFT.methods.revealed().call();
							this.setState({ _revealedStatus: revealedStatus });

							myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
							console.log("myStakedTokenCount : " + myStakedTokenCount);

							rewardsAmount = await contractStaking.methods.rewardsAmount().call();
							this.setState({ _rewardsAmount: rewardsAmount });

							myTokenAmount = await contractToken.methods.balanceOf(account).call();
							this.setState({ _myTokenAmount: myTokenAmount });

							milestone = await contractStaking.methods.milestone(account).call();
							this.setState({ _milestone: milestone });

							totalRewardReleased = await contractStaking.methods.totalRewardReleased(account).call();
							this.setState({ _totalRewardReleased: totalRewardReleased });
							console.log("totalRewaedReleased:" + totalRewardReleased);

							let a;
							for (a = 0; a < myStakedTokenCount; a++) {
								getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
								this.setState({ _getMyStakedTokens: getMyStakedTokens });
								console.log("getMyStakedTokens : " + getMyStakedTokens);

								let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
								let timeDuration = Date.now() - (tokenTime * 1000);

								if (timeDuration >= 0) {

									getRewards[a] = (Date.now() - (tokenTime * 1000)) * 1 / 86400000;

								} else {
									getRewards[a] = 0;
								}

								elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(4)];
								this.setState({ _elementArray: elementArray });

								this.setState({ _getRewards: getRewards });
								console.log("tokenTime :" + tokenTime * 1000);
								console.log("tokenTimeDuration :" + timeDuration);
								console.log("getRewards : " + getRewards[a].toFixed(2));

								this.setState({ _getMyStakedTokens: getMyStakedTokens });
								console.log("getMyStakedTokens : " + getMyStakedTokens);
								console.log("elementArray[a] : " + elementArray[a]);
								console.log("elementArray[a][0] : " + elementArray[a][0]);

							}


							for (let q = 0; q < getRewards.length; q++) {
								pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
								this.setState({ _newPendingRewards: pendingRewards * 1 });
								console.log("_newPendingRewards :" + this.state._newPendingRewards);

							}



							for (let y = 0; y < this.state.myNFTWallet; y++) {
								myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs })

							}


							console.log("myTokenIDs : " + myTokenIDs[0]);
							console.log("myTokenIDs : " + this.state._myTokenIDs);

							owner = await contractNFT.methods.owner().call();
							console.log("Owner" + owner);


						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}
		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload(true);

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	setApprovalForAll = async event => {
		event.preventDefault();

		try {
			await contractNFT.methods.setApprovalForAll(addressStaking, true).send({ from: account });
		} catch (err) {
			console.log(err);
		}

		window.location.reload(true);

	}

	async stake(staking_id) {


		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + staking_id);

		try {
			isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
			this.setState({ _isApproved: isApproved });
			console.log(isApproved);

			if (isApproved) {
				await contractStaking.methods.stakeNFT(staking_id).send({ from: account });

			} else {

				await contractNFT.methods.setApprovalForAll(addressStaking, true).send({ from: account });

			}

		} catch (err) {
			console.log(err);
		}
		window.location.reload(true);


	}

	batchStake = async event => {
		event.preventDefault();

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + myTokenIDs);

		try {
			isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
			this.setState({ _isApproved: isApproved });
			console.log(isApproved);

			if (isApproved) {
				await contractStaking.methods.batchStakeNFT(myTokenIDs).send({ from: account });

			} else {

				await contractNFT.methods.setApprovalForAll(addressStaking, true).send({ from: account });

			}

		} catch (err) {
			console.log(err);
		}

		window.location.reload(true);

	}

	batchUnstake = async event => {
		event.preventDefault();

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + getMyStakedTokens);

		try {

			await contractStaking.methods.batchUnstakeNFT(getMyStakedTokens).send({ /*gasLimit: 285000,*/ from: account });


		} catch (err) {
			console.log(err);
		}
		window.location.reload(true);

	}

	batchClaimRewards = async event => {
		event.preventDefault();

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + getMyStakedTokens);

		try {

			await contractStaking.methods.batchClaimRewards(getMyStakedTokens).send({ /*gasLimit: 285000,*/ from: account });

		} catch (err) {
			console.log(err);
		}
		window.location.reload(true);

	}

	async unstake(unstaking_id) {

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + unstaking_id);

		try {

			await contractStaking.methods.unstakeNFT(unstaking_id).send({ /*gasLimit: 285000,*/ from: account });


		} catch (err) {
			console.log(err);
		}
	}

	getStakedDetails = async event => {
		event.preventDefault();

		if (this.state.walletAddress != "") {
			getStakedDetailsWindow = 1;
			this.setState({ _getStakedDetailsWindow: getStakedDetailsWindow });
			console.log("getStakedDetailsWindow :" + getStakedDetailsWindow);
		}

		try {

			myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
			console.log("myStakedTokenCount : " + myStakedTokenCount);

			let a;
			for (a = 0; a < myStakedTokenCount; a++) {
				getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
				//	getRewards = await contractStaking.methods.getRewards(getMyStakedTokens[a]).send({ from: account });

				let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
				let timeDuration = Date.now() - (tokenTime * 1000);

				if (timeDuration >= 0) {

					getRewards[a] = (Date.now() - (tokenTime * 1000)) * 1 / 86400000;

				} else {
					getRewards[a] = 0;
				}

				elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(4)];
				this.setState({ _elementArray: elementArray });

				this.setState({ _getRewards: getRewards });
				console.log("tokenTime :" + tokenTime * 1000);
				console.log("tokenTimeDuration :" + timeDuration);
				console.log("getRewards : " + getRewards[a].toFixed(2));

				this.setState({ _getMyStakedTokens: getMyStakedTokens });
				console.log("getMyStakedTokens : " + getMyStakedTokens);
				console.log("elementArray[a] : " + elementArray[a]);
				console.log("elementArray[a][0] : " + elementArray[a][0]);

			}


			for (let q = 0; q < getRewards.length; q++) {
				pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
				this.setState({ _newPendingRewards: pendingRewards * 1 });
				console.log("_newPendingRewards :" + this.state._newPendingRewards);

			}


		} catch (err) {
			console.log(err);
		}

	}

	getMyOwnNFTDetails = async event => {
		event.preventDefault();

		if (this.state.walletAddress != "") {
			getMyOwnNFTDetailsWindow = 1;
			this.setState({ _getMyOwnNFTDetailsWindow: getMyOwnNFTDetailsWindow });
			console.log("getMyOwnNFTDetailsWindow :" + getMyOwnNFTDetailsWindow);
		}

		try {


			for (let y = 0; y < this.state.myNFTWallet; y++) {
				myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
				this.setState({ _myTokenIDs: myTokenIDs })

			}


		} catch (err) {
			console.log(err);
		}

	}

	closeWindow = async event => {
		event.preventDefault();

		getStakedDetailsWindow = 0;
		this.setState({ _getStakedDetailsWindow: getStakedDetailsWindow });

	}

	closeWindow2 = async event => {
		event.preventDefault();

		getMyOwnNFTDetailsWindow = 0;
		this.setState({ _getMyOwnNFTDetailsWindow: getMyOwnNFTDetailsWindow });

	}

	async claimRewards(claiming_id) {

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + claiming_id);

		try {

			await contractStaking.methods.claimRewards(claiming_id).send({ /*gasLimit: 285000,*/ from: account });

		} catch (err) {
			console.log(err);
		}
		window.location.reload(true);

	}

	register = async event => {
		event.preventDefault();

		try {

			await contractStaking.methods.register(this.state._email).send({ from: account });

		} catch (err) {
			console.log(err);
		}

		window.location.reload(true);

	}

	claimAwards = async event => {
		event.preventDefault();

		try {

			await contractStaking.methods.claimAwards().send({ from: account });

		} catch (err) {
			console.log(err);
		}

		window.location.reload(true);

	}


	render() {

		return (

			<div class="stakeMain">
				<div class="stakeMain2">
					<div class="navBarStake">
						<div class="navBarStake2">
							<div class="tName"><img class="logoNFT" onClick={() => window.location.href = '/'} src={logo} /></div>

							{this.state.walletAddress === '' ?
								(<form class="stakeWCMain" onSubmit={this.walletConnect}>
									<button class="stakeWC" >Wallet Connect</button>
								</form>) : (<form class="stakeWCMain" onSubmit={this.walletDisconnect}><button class="stakeWC" >
									{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}
						</div>
					</div>
					<div class="markBarMain">
						<div class="markBar">

							<div class="markBarCon">
								<img src={pic1} />
								<div class="markBar2">
									<div class="num">{this.state._getMyStakedTokens.length}</div>
									<div class="name">STAKED</div>
								</div>
							</div>

							<div class="markBarCon">
								<img src={pic2} />
								<div class="markBar2">
									<div class="num">{this.state._rewardsAmount} / DAY</div>
									<div class="name">YIELD</div>
								</div>
							</div>

							<div class="markBarCon">
								<img src={pic3} />
								<div class="markBar2">
									<div class="num">{(this.state._myTokenAmount / 1000000000).toFixed(2)}</div>
									<div class="name">BALANCE</div>
								</div>
							</div>

							<div class="markBarCon">
								<img src={pic4} />
								<div class="markBar2">
									<div class="num">{(this.state._newPendingRewards).toFixed(2)}</div>
									<div class="name">PENDING</div>
								</div>
							</div>
							<div class="btnsMainBig">
								{this.state._newPendingRewards < 1 ?

									(<form onSubmit={this.batchClaimRewards}><button class="btnsDis" disabled>CLAIM</button></form>) :
									(<form onSubmit={this.batchClaimRewards}><button class="btns">CLAIM</button></form>)}							{this.state._getMyStakedTokens.length < 1 ?
										(<form onSubmit={this.batchUnstake}><button class="btns2Dis" disabled>UNSTAKE ALL</button></form>) :
										(<form onSubmit={this.batchUnstake}><button class="btns2">UNSTAKE ALL</button></form>)}
							</div>
						</div>

					</div>

					<div class="imageSet">
						<img src={pet1} />
					</div>

					<div class="boardMain">
						<div class="board">

							<div class="boardPics">
								<div class="stakeT">STAKE</div>

							</div>

							<div class="boardPics">
								<div class="image2Size">
									<img class="rabbitImgLogo" src={nft} />
									<div class="num2">EXO WARRIOR: {this.state.myNFTWallet}</div>
									<div class="name2">Available</div>
								</div>

								<div class="image2Size">
									<img class="rabbitImg" src={coins} />
									<div class="num2">$PLASMA: {this.state._rewardsAmount}</div>
									<div class="name2">Per Staked Daily</div>
								</div>
							</div>


							<div class="walletConnect">
								<form class="formStake" onSubmit={this.getMyOwnNFTDetails}>
									<button class="wallet3" type='submit'>EXO WARRIOR to Stake<img class="lookDown" src={down} /></button>
								</form>
							</div>

							<div class="approveBtns">
								{this.state._isApproved === false ?
									(<form onSubmit={this.setApprovalForAll}><button class="approveBtns1">APPROVE</button></form>) :
									(<form onSubmit={this.batchStake}><button class="approveBtns1">STAKE ALL</button></form>)}
							</div>

						</div>

						<div class="board">

							<div class="boardPics">
								<div class="stakeT">UNSTAKE</div>


							</div>

							<div class="boardPics">
								<div>
									<img class="rabbitImgLogo" src={nft} />
									<div class="num2">EXO WARRIOR: {this.state._getMyStakedTokens.length}</div>
									<div class="name2">Staked</div>
								</div>
							</div>

							<div class="walletConnect">
								<form class="formStake" onSubmit={this.getStakedDetails}>
									<button class="wallet3" type='submit'>EXO WARRIOR to Unstake<img class="lookDown" src={down} /></button>
								</form>
							</div>

							<div class="approveBtns">
								{this.state._getMyStakedTokens.length < 1 ?
									(<form onSubmit={this.batchUnstake}><button class="approveBtns3" disabled>UNSTAKE ALL</button></form>) :
									(<form onSubmit={this.batchUnstake}><button class="approveBtns3">UNSTAKE ALL</button></form>)}
							</div>

						</div>
					</div>

					<div class="winnerSection">
						<div class="winnerT">Prize Board</div>

						<table>
							<tr>
								<th>#</th>
								<th>$PLASMA RANGE</th>
								<th>PRIZE</th>
								<th>YOUR ACHIEVEMENT</th>
							</tr>

							<tr>
								<td>1</td>
								<td>40 - 60</td>
								<td>BNB</td>
								<td>
									{(this.state._totalRewardReleased >= 40000000000 && this.state._totalRewardReleased < 60000000000) ?
										(<button onClick={this.claimAwards} class="prizeBtn">Claim Prize</button>) :
										(<button class="prizeBtnDis" disabled>Claim Prize</button>)
									}
								</td>
							</tr>

							<tr>
								<td>2</td>
								<td>80 - 100</td>
								<td>BNB</td>
								<td>
									{(this.state._totalRewardReleased >= 80000000000 && this.state._totalRewardReleased < 100000000000) ?
										(<button onClick={this.claimAwards} class="prizeBtn">Claim Prize</button>) :
										(<button class="prizeBtnDis" disabled>Claim Prize</button>)
									}
								</td>
							</tr>

							<tr>
								<td>3</td>
								<td>120 - 150</td>
								<td>BNB</td>
								<td>
									{(this.state._totalRewardReleased >= 120000000000 && this.state._totalRewardReleased < 150000000000) ?
										(<button onClick={this.claimAwards} class="prizeBtn">Claim Prize</button>) :
										(<button class="prizeBtnDis" disabled>Claim Prize</button>)
									}
								</td>
							</tr>

							<tr>
								<td>4</td>
								<td>180 - 200</td>
								<td>BNB</td>
								<td>
									{(this.state._totalRewardReleased >= 180000000000 && this.state._totalRewardReleased < 200000000000) ?
										(<button onClick={this.claimAwards} class="prizeBtn">Claim Prize</button>) :
										(<button class="prizeBtnDis" disabled>Claim Prize</button>)
									}
								</td>
							</tr>

							<tr>
								<td>5</td>
								<td>250 - 270</td>
								<td>Action Figure</td>
								<td>
									{(this.state._totalRewardReleased >= 250000000000 && this.state._totalRewardReleased < 270000000000) ?
										(<button onClick={this.claimAwards} class="prizeBtn">Claim Prize</button>) :
										(<button class="prizeBtnDis" disabled>Claim Prize</button>)
									}
								</td>
							</tr>

							<tr>
								<td>6</td>
								<td>290 - 310</td>
								<td>BNB</td>
								<td>
									{(this.state._totalRewardReleased >= 290000000000 && this.state._totalRewardReleased < 310000000000) ?
										(<button onClick={this.claimAwards} class="prizeBtn">Claim Prize</button>) :
										(<button class="prizeBtnDis" disabled>Claim Prize</button>)
									}
								</td>
							</tr>

							<tr>
								<td>7</td>
								<td>330 - 350</td>
								<td>BNB</td>
								<td>
									{(this.state._totalRewardReleased >= 330000000000 && this.state._totalRewardReleased < 350000000000) ?
										(<button onClick={this.claimAwards} class="prizeBtn">Claim Prize</button>) :
										(<button class="prizeBtnDis" disabled>Claim Prize</button>)
									}
								</td>
							</tr>

							<tr>
								<td>8</td>
								<td>370 - 390</td>
								<td>BNB</td>
								<td>
									{(this.state._totalRewardReleased >= 370000000000 && this.state._totalRewardReleased < 390000000000) ?
										(<button onClick={this.claimAwards} class="prizeBtn">Claim Prize</button>) :
										(<button class="prizeBtnDis" disabled>Claim Prize</button>)
									}
								</td>
							</tr>

							<tr>
								<td>9</td>
								<td>420 - 450</td>
								<td>BNB</td>
								<td>
									{(this.state._totalRewardReleased >= 420000000000 && this.state._totalRewardReleased < 450000000000) ?
										(<button onClick={this.claimAwards} class="prizeBtn">Claim Prize</button>) :
										(<button class="prizeBtnDis" disabled>Claim Prize</button>)
									}
								</td>
							</tr>

							<tr>
								<td>10</td>
								<td>500</td>
								<td>Action Figure</td>
								<td>
									{(this.state._totalRewardReleased >= 500000000000) ?
										(<button onClick={this.claimAwards} class="prizeBtn">Claim Prize</button>) :
										(<button class="prizeBtnDis" disabled>Claim Prize</button>)
									}
								</td>
							</tr>

						</table>
					</div>

					<div class="emailSection">
						<div class="emailSection2">
							<div class="emailSectionH">PLS REGISTER HERE TO RECEIVE THE PRIZES</div>
							<form onSubmit={this.register}><textarea class="buyDetails2" id="w3review" name="story" placeholder="email" value={this.state._email}
								onChange={event => this.setState({ _email: event.target.value })}>
							</textarea>
								<div><button type="submit" class="submitBtn">SUBMIT</button></div>
							</form>
						</div>
					</div>

					{this.state._getStakedDetailsWindow > 0 ? (
						<div class="formMain1">
							<div class="formMain">

								<div class="tS">My Staked NFTs</div>

								<div class="tokenIds" >
									{this.state._elementArray.map((element1) => {

										console.log("ID :" + element1[0]);
										console.log("Time :" + element1[1]);

										var ID = element1[0] + 1;

										if (true) {

											if (element1[0] == 0) {
												var url = "https://stake-projectexo.com/imagesWarrior/" + ID + ".png";;
												console.log("url :" + url);


											} else {

												var url = "https://stake-projectexo.com/imagesWarrior/" + ID + ".png";;
												console.log("url :" + url);

											}

										} else {
											url = secretGif;
										}

										console.log("url :" + url);
										console.log("element1 :" + element1[0]);
										console.log("rewards1 :" + element1[1]);

										return (
											<div class="tokenIds2">
												<div class="flip-card">
													<div class="flip-card-inner">
														<div class="flip-card-front">
															<div class="unStakeImg"><img class="images_show" src={url} /></div>
														</div>

														<div class="flip-card-back">

															<button class="stake2" onClick={() => this.claimRewards(element1[0])}>Claim</button>

															<div class="elementName2">{element1[1]}</div>
															<div class="elementName3">ID : {element1[0]}</div>
															<button class="stake3" onClick={() => this.unstake(element1[0])}>Unstake</button>
														</div>
													</div>
												</div></div>);

									})}
								</div>

								<form onSubmit={this.closeWindow}>
									<button class="closeNote">✖</button>
								</form>
							</div></div>) : null}


					{this.state._getMyOwnNFTDetailsWindow > 0 ?
						(<div class="formMain1">
							<div class="formMain">
								<div class="tS">My Own NFTs</div>

								<div class="tokenIds" >

									{this.state._myTokenIDs.map((element) => {

										var ID = element + 1;

										if (true) {


											if (element == 0) {
												var url = "https://stake-projectexo.com/imagesWarrior/" + ID + ".png";
												console.log("url :" + url);


											} else {

												var url = "https://stake-projectexo.com/imagesWarrior/" + ID + ".png";
												console.log("url :" + url);

											}

										} else {
											url = secretGif;
										}

										return (
											<div class="ownNFT">
												<div class="flip-card">
													<div class="flip-card-inner">
														<div class="flip-card-front">

															<img class="images_show" src={url} /></div>
														<div class="flip-card-back">
															<div class="elementName">{element}</div>
															{this.state._isApproved ?
																(<button class="stake" onClick={() => this.stake(element)}>Stake</button>) :
																(<button class="stake" onClick={() => this.stake(element)}>Approve</button>)
															}
														</div>
													</div>
												</div></div>);



									})}


								</div>
								<form onSubmit={this.closeWindow2}>
									<button class="closeNote">✖</button>
								</form>
							</div></div>
						) :
						null}

					<img class="v1" src={v2} />
					<div class="triangleTopRight"></div>

					<img class="sticky" src={bgNft} />

					<div class="blur"></div>
					<div class="v2Quote">
						<div>AVAILABLE IN V2 STAKING</div>
					 <img class="back" src={back} onClick={() => window.location.href = '/'}/>
					</div>					

				</div>
			</div>)
	}
}

export default WarriorStake;